.alpha-puark--faq-page {
  visibility: visible;

  &--header {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: $primary-purple;
      opacity: 0.1;
      z-index: -1;
    }

    div.section-content {
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
        opacity: 0.5;
        filter: blur(8.125rem);
        background: linear-gradient(180deg, $primary-purple-02 0%, $primary-purple-01 100%);
      }

      &::before {
        top: -540px;
        right: -400px;
        width: 754px;
      }
      &::after {
        bottom: -840px;
        left: -100px;
        width: 958px;
      }
    }
  }

  &--content {
    position: relative;

    &--accordion {
      position: relative;
      background-color: $color-dark-04;
      padding: 1.25rem 2.5rem;
      border-radius: 0.5rem;
      cursor: pointer;

      @include respond(md) {
        padding: 0.75rem 1rem;
      }

      > label {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        cursor: pointer;

        > p {
          flex: 1;
          margin: 0 1rem;
          font-size: 1rem;
          line-height: 2rem;
          font-weight: 600;

          @include respond(md) {
            font-size: 0.75rem;
            line-height: 1.125rem;
          }
        }

        > svg {
          color: $primary-purple-01;
          width: 2rem;
          height: 2rem;
          transform: rotate(0deg);
          transition: transform 0.2s ease;

          @include respond(md) {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }

      > div {
        text-align: justify;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 300;
        color: rgba(white, 0.6);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease;
        cursor: auto;

        @include respond(phone) {
          text-align: left;
          font-size: 0.875rem;
        }

        > span {
          display: block;
          margin-top: 1.5rem;

          @include respond(phone) {
            margin-top: 1rem;
          }

          a {
            color: $primary-purple-01;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &.active {
        --max-height: 36rem;
        > label > svg:last-child {
          transform: rotate(180deg);
        }
        > div {
          max-height: calc(var(--max-height) + 1.5rem);
        }
      }
    }
  }
}