.landing-borrowers {
    &--image {
        position: relative;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translateX(-50%) translatey(-50%);
            aspect-ratio: 1 / 1;
            content: '';
            @extend .background-linear-gradient;
            opacity: 0.5;
        }
    }
}
