.textarea {
    background-color: $bg-content;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(white, 0.2);
    height: 13rem;

    font-size: 1.125rem;
    letter-spacing: 0.04rem;
    line-height: 1.75rem;
    color: rgba(white, 0.87);

    &:focus-visible {
        outline: none;
    }
}