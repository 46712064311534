.alpha-puark--radio {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    color: rgba(white, 0.6);

    > input {
        display: none;
    }

    > span {
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: 0.04rem;
        color: currentColor;
        transition: color 0.1s ease;
        font-weight: 400;
    }

    & > div {
        --size: 1.25rem;
        position: relative;
        border-radius: 100%;
        width: var(--size);
        height: var(--size);
        border: 2px solid currentColor;
        margin-right: 0.625rem;
        cursor: pointer;
        transition: color 0.1s ease;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: calc(var(--size) / 2);
            height: calc(var(--size) / 2);
            background-color: transparent;
            border-radius: 100%;
            transition: background-color 0.1s ease;
        }

        &:hover {
            color: $text-01;
        }
    }

    & input:checked {
        & ~ div::before {
            background-color: currentColor;
        }

        & ~ span,
        & ~ div {
            color: $text-01;
            font-weight: 700;
        }
    }
}
