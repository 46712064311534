.landing-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    .logo {
        // height: 100px;
        // width: 150px;
        img {
            height: 24px;
            width: 100%;
        }
    }
    .menu-wraper {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
    }
}

$top-header-h: 60px;

#mobile-header {
    overflow-x: hidden;
    display: none;
    z-index: 1000;
    width: 100vw;
    // position: fixed !important;
    // top: 0;
    // left: 0;
    height: $top-header-h;
    // & + div {
    //     margin-top: $top-header-h;
    // }
    header {
        z-index: inherit;
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100vw;
        height: $top-header-h;
    }

    .sidebar {
        min-width: 100vw;
        background: rgba(0, 0, 0, 0.6);
        height: calc(100vh - #{$top-header-h});
        position: fixed;
        left: 0;
        top: $top-header-h;
        z-index: inherit;

        .sidebar__inner {
            width: 300px;
            height: 100%;
            overflow-y: auto;
            position: absolute;
            left: 0;
            top: 0;
            animation-duration: 0.5s;
            animation-delay: 0;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        &.open {
            display: block;
            .sidebar__inner {
                animation-name: sidebar-scroll-in;
            }
        }

        &.close {
            transition: all 0.5s ease;
            opacity: 0;
            .sidebar__inner {
                animation-name: sidebar-scroll-out;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    #mobile-header {
        display: block;
    }
    #main-header {
        display: none;
    }
}

@keyframes sidebar-scroll-in {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes sidebar-scroll-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
