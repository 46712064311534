.section-lenders {
    position: relative;
    mix-blend-mode: screen;
    background-blend-mode: screen;
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: -1;
        opacity: 0.15;
    }
}

.class-block-lenders {
    position: relative;
    > img {
        width: 666px;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 800px;
        height: 800px;
        content: '';
    }
}
