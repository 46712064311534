.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    padding: 4px 15px;
    font-size: 14px;
    font-weight: 400;
    color: $color-primary;
    background-color: $color-white;
    border: 1px solid $color-primary;

    // disabled
    &:disabled {
        cursor: not-allowed;
        color: rgba($color-black, 0.2);
        border-color: $color-white-2;
        background-color: $color-white-1;
    }

    // type
    &-primary {
        color: $color-white;
        background-color: $color-primary;
    }

    &-dashed {
        border: 1px dashed $color-primary;
    }

    &-text {
        border: none;
        &:disabled {
            background-color: $color-white;
        }
    }

    &-link {
        border: none;
        color: $color-link;
        &:disabled {
            background-color: $color-white;
        }
    }

    // size
    &-large {
        padding: 6.4px 15px;
        font-size: 16px;
    }

    &-small {
        padding: 0 7px;
        font-size: 14px;
    }

    // shape
    &-circle {
        border-radius: 50%;
    }

    &-round {
        border-radius: 40px;
    }

    // danger

    &-danger {
        color: $color-error;
        border-color: $color-error;
    }
    &-primary.btn-danger {
        color: $color-white;
        background-color: $color-error;
    }

    // hover
    &-hover {
        animation: all 0.2s;
        &:hover {
            transform: translateY(-2px);
        }
    }

    // shadow
    &-shadow {
        box-shadow: 0 19px 20px -11px rgba($color-black-1, 0.39),
            4px 4px 0 0 $color-black-1;
    }

    // icon
    &-icon {
        width: 1.5rem;
        margin: 0 1.125rem 0 0;
    }

    &-icon-right {
        flex-direction: row-reverse;
        & > .btn-icon {
            margin: 0 0 0 1.125rem;
        }
    }

    &-icon-top {
        flex-direction: column;
        & > .btn-icon {
            margin: 0;
        }
    }

    &-icon-bottom {
        flex-direction: column-reverse;
        & > .btn-icon {
            margin: 0;
        }
    }
}
