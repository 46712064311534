@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
* {
    font-family: 'Inter', sans-serif;
}

body {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.7;
    letter-spacing: 0.04rem;
    color: $color-white;
}

.title-primary {
    font-family: 'Inter';
    display: block;
    color: $color-primary;
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
    letter-spacing: 0.12em;
    text-transform: capitalize;
}

.heading-primary {
    font-family: Georgia, 'Times New Roman', Times, serif;
    display: block;
    font-size: 3.5rem;
    line-height: 130%;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: rgba($color-white, 0.87);
    &--sub-1 {
    }
}

.heading-secondary {
    font-family: 'Inter', sans-serif;
    font-size: 2.25rem;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 3.375rem;
    letter-spacing: 0.04em;
    color: rgba($color-white, 0.87);
    display: block;
}

.heading-tertiary {
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    letter-spacing: 0.04em;
    color: rgba($color-white, 0.87);
    display: block;
}

.paragraph {
    font-size: 1.5rem;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: rgba($color-white, 0.6);
}

.alpha-puark--scroll {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: $bg-content;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(white, 0.25);
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(white, 0.5);
    }
}

.heading-1 {
    font-size: 42px;
    line-height: 54px;
    font-weight: 700;
    color: $text-01;
    font-family: 'Lora';
    letter-spacing: 0.03em;

    @include respond(phone) {
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
    }
}
.heading-12 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    color: $text-01;
    letter-spacing: 0.03em;
    font-family: 'Lora';
    @include respond(phone) {
        font-size: 18px;
    }
}

.heading-123 {
    font-size: 24px;
    font-weight: 500;
    color: $color-primary;
    line-height: 1.5;
    font-family: 'Lora';

    @include respond(phone) {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}

.heading-2 {
    font-size: 20px;
    font-weight: 600;
    color: $text-01;
    line-height: 30px;
    font-family: 'Inter';
    @include respond(phone) {
        font-size: 14px;
        line-height: 21px;
    }
}
.heading-21 {
    font-size: 20px;
    font-weight: 700;
    color: $text-01;
    line-height: 142%;
    font-family: 'Inter';
    letter-spacing: 0.03em;
    text-transform: capitalize;
    @include respond(phone) {
        font-size: 16px;
        line-height: 19px;
    }
}
.heading-3 {
    font-size: 18px;
    font-weight: bold;
    color: $text-01;
    line-height: 27px;
    font-family: 'Inter';

    @include respond(phone) {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
}

.heading-3-1 {
    font-size: 18px;
    font-weight: bold;
    color: $text-01;
    line-height: 27px;
    font-family: 'Inter';

    @include respond(phone) {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
    }
}

.heading-31 {
    font-size: 16px;
    font-weight: 400;
    color: $text-01;
    line-height: 24px;
    font-family: 'Inter';
    letter-spacing: 0.02em;

    @include respond(phone) {
        font-size: 14px;
        line-height: 24px;
    }
}

.heading-4 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: $text-01;
    font-family: 'Inter';
    letter-spacing: 0.02em;

    @include respond(phone) {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }
}

.heading-5 {
    font-size: 16px;
    font-weight: bold;
    color: $text-01;
    line-height: 24px;
    font-family: 'Inter';

    @include respond(phone) {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }
}

.text-btn {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: rgba(255, 255, 255, 1) !important;
    line-height: 22px !important;
    font-family: 'Inter';

    @include respond(phone) {
        font-size: 14px !important;
        line-height: 21px !important;
    }
}

.text-body-md {
    font-size: 18px;
    line-height: 32px;
    color: $text-02;
    font-family: 'Inter';
    font-weight: 400;
    letter-spacing: 0.02em;

    @include respond(phone) {
        font-size: 14px;
        line-height: 24px;
    }
}

.text-body-base {
    font-size: 16px;
    line-height: 24px;
    color: $text-01;
    font-family: 'Inter';

    @include respond(phone) {
        font-size: 14px;
        line-height: 21px;
    }
}

.text-body-base-1 {
    font-size: 16px;
    line-height: 24px;
    color: $text-02;
    font-family: 'Inter';

    @include respond(phone) {
        font-size: 14px;
        line-height: 21px;
    }
}

.text-body-sm {
    font-size: 14px;
    line-height: 24px;
    color: $text-02;
    font-family: 'Inter';

    @include respond(phone) {
        font-size: 12px;
        line-height: 18px;
    }
}
.font-itc {
    font-family: 'ITC Garamond Std' !important;
}

.text-tiny {
    font-size: 11px;
    line-height: 13px;
    color: $text-02;
    font-family: 'Inter';
    font-weight: 400;
}

.text-error {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: $color-error-1;
    font-family: 'Inter';

    @include respond(phone) {
        font-size: 12px;
        line-height: 18px;
    }
}

.color-primary-gradient {
    background: $color-primary-gradient;
}

.color-secondary-gradient {
    background: $color-secondary-gradient;
}

.text-link-1 {
    color: $primary-purple-05;
    font-size: 16px;
    font-weight: 700;
    &:hover {
        text-decoration: underline;
    }

    @include respond(phone) {
        font-size: 14px;
    }
}

.text-link-2 {
    color: $primary-purple-05;
    font-size: 12px;
    font-weight: 700;
    &:hover {
        text-decoration: underline;
    }
}
