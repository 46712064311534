.menu {
    display: flex;
}

.menu__item {
    .submenu {
        $w: 155px;

        min-width: $w;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        display: none;
        z-index: 10;
        left: 50%;
        li {
            padding: 1.25rem 2rem;
            text-align: center;
            // background-color: $color-dark;
            &:hover {
                background-color: $color-dark-02;
            }

            &:first-child {
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
            }
            &:last-child {
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
            }
            a {
                text-align: center;
            }
        }
        &:hover {
            display: block;
        }
        ::before {
            content: '';
            display: block;
            z-index: inherit;

            border: 16px solid transparent;
            border-bottom-color: $color-dark;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &:hover {
        .submenu {
            display: block;
        }
    }

    .main-path {
        &::after {
            $w: 11.18px;
            $h: 6.59px;
            content: '';
            width: $w;
            height: $h;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            background-image: url('/src/assets/svgs/arrow-down.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: transform 0.5s ease;
        }

        &.active::after {
            background-image: url('/src/assets/svgs/puple-arrow.png');
        }
    }
    &:hover {
        .main-path::after {
            transform: rotate(-180deg);
            transform-origin: center;
        }
    }
}

.menu__item-mobile {
    &.current {
        .accordion__title {
            color: #9406fe;
            font-weight: 700;
            &::after {
                background-image: url('/src/assets/svgs/puple-arrow.png');
            }
        }
        .single-link {
            color: #9406fe;
            font-weight: 700;
        }
    }
    .accordion__title {
        padding: 16px 32px;
    }

    .submenu {
        li {
            padding: 16px 32px;
        }
    }

    .single-link {
        width: 100%;
        display: inline-block;
        padding: 16px 32px;
    }
}
