.progress-bar {

    &__bar {
        margin-top: 1rem;

        @include respond(phone) {
            margin-top: 12px;
            height: 2px;
        }
        height: 3px;
        border-radius: 0.5rem;
        transition: width 0.5 ease;
        width: 0;
    }
    &.progress-bar__loading {
        span {
            background-color: rgba(255, 255, 255, 0.3);
            padding: 6px 32px;
            border-radius: 8px;
            animation-name: change-bg;
            animation-duration: 1s;
            animation-delay: 0.5s;
            animation-iteration-count: infinite;
        }

        .progress-bar__bar {
            animation-name: change-bg;
            animation-duration: 1s;
            animation-delay: 0;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes change-bg {
    0% {
        background-color: rgba(255, 255, 255, 0.3)
    }
    100% {
        background-color: rgba(255, 255, 255, 0.2)
    }
}