.alpha-puark--button-group {
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0.25rem;
    border-radius: 0.5rem;
    background-color: $bg-content;

    > button.btn {
      padding: 0.594rem 1rem !important;
      font-size: 0.875rem !important;
      line-height: 21px;
      letter-spacing: 0.04rem;
      font-weight: 600 !important;
      color: white;
      border-radius: 0.25rem;

      > img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.625rem;
      }
    }
    > button.btn.btn-dark {
        background-color: transparent !important;
        &:hover > img {
          filter: none !important;
        }
    }
    > button.btn.btn-gradient-primary {
    }
}
