.avatar-badge {
    $w: 2.5rem;
    width: $w;
    height: $w;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
    }
    span {
        $w: 1rem;

        display: block;
        position: absolute;
        bottom: 0;
        right: 0;

        min-width: $w;
        min-height: $w;
        width: fit-content;
        height: $w;
        padding: 0 0.25rem;
        
        text-align: center;
        line-height: $w;
        font-size: 0.688rem;
        font-weight: 500;

        border-radius: $w;
        background-image: $color-primary-gradient;

        @include respond(sm) {
            $w: 0.8rem;
            min-width: $w;
            min-height: $w;
            height: $w;
            font-size: 0.625rem;
        }
    }
}