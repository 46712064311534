@import '@/styles/_mixins.scss';

$text-01: rgba(255, 255, 255, 0.87);
.footer-alpha-quark {
    a {
        font-size: 1rem;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: 0.02em;
        &:hover {
            text-decoration-line: underline;
        }
        font-family: Inter;

        &:first-child {
            margin-bottom: 2rem;
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 27px;
            color: $text-01;
        }

        @include respond(md) {
            font-size: 14px;
            line-height: 17px;
        }
        @include respond(phone) {
            font-size: 12px;
            line-height: 15px;
        }
    }

    &--bLink {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > * {
            margin-bottom: 1rem;
            &:first-child {
                margin-bottom: 1.5rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        @include respond(phone) {
            * {
                margin-bottom: 0.5rem;
                &:first-child {
                    margin-bottom: 0.75rem;
                }
            }
        }
    }
}
