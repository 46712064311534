
.table-wraper01 {
    min-width: 100%;
    overflow: overlay;
    // padding-bottom: 10px;
    height: fit-content;

    .table01 {
        width: 100%;
        overflow: auto;
        display: fixed;
        border-collapse: collapse;
        td, th {
            padding: 1.25rem 1rem;
            text-align: left;
            overflow: hidden;
            min-width: fit-content;
        }
        th {
            font-size: 1rem;
            color: $text-01;
            font-weight: normal;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: fit-content;

            @include respond(phone) {
                font-size: 14px;
            }
        }
        td {
            font-size: 1rem;
            color: $text-02;
            font-weight: normal;
            text-overflow: ellipsis;
            white-space: nowrap;
            @include respond(phone) {
                font-size: 14px;
            }

        }
        thead {
            position: sticky;
            top: 0;
            width: calc( 100% - 1em )
    
        }
        tbody {
            width: 100%;
            max-height: 737px;
            overflow: overlay;
            &::-webkit-scrollbar {
                width: 10px;
                height: 4px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $color-dark-02;
                border-radius: 5px;
    
            }
        }
        
        tr.pseudo-row {
            td, th {
                padding-top: 0;
                padding-bottom: 0;

            }
        }
        
    }
    .table-head {
        position: sticky;
        top: 0;
        min-width: fit-content;
    }

    .table-data {
        width: 100%;
        min-width: fit-content;
        max-height: 335px;
        overflow-y: overlay;
        overflow-x: visible;
        // position: relative;
        display: block;
        margin-top: -67px;
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-dark-02;
            border-radius: 5px;
        }

    }
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color-dark-02;
        border-radius: 5px;
    }
}

// .response-text-table {
//     @include respond(phone) {
//         & > table > thead > tr > th {
//             font-size: 14px;
//         }
//     }
// }
