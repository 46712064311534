.loader {
    border: 2px solid $color-dark;
    border-radius: 50%;
    border-top: 2px solid $color-white-1;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}
  
