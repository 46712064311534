.btn-gradient-primary {
    background-color: $color-primary !important;
    padding: 1.125rem 2.5rem;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: 0.02em;
    border-radius: 8px;
    color: $color-white;
    border: none;
    > span {
        display: flex;
        align-items: center;
    }
    // &:hover {
    //     color: rgba($color-black, 0.8);
    // }
    &:disabled {
        background-image: none !important;
        background-color: rgba($color-dark, 0.8);
    }

    @include respond(phone) {
        padding: 14px 16px;
    }
}

.btn-dark {
    background-color: $color-dark;
    padding: 1.125rem 2.5rem;
    font-size: 18px;
    font-weight: 700;
    line-height: 2.25rem;
    border: 1px solid #3464fd;
    border-radius: 8px;
    color: $color-white;
    > span {
        display: flex;
        align-items: center;
    }
    &:disabled {
        background-color: rgba($color-dark, 0.8);
    }
    &:hover {
        // color: rgba($color-black, .8);
        & > img {
            filter: blur(400px);
        }
    }
}

.alpha-puark--button__dash {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(white, 0.2);
    border-radius: 0.5rem;
    background-color: $bg-content;
    user-select: none;
    white-space: nowrap;
    font-size: 0.875rem;
    line-height: 1.313rem;
    letter-spacing: 0.04rem;
    color: rgba(white, 0.9);

    &--icon__left,
    &--icon__right {
        width: 1rem;
        height: 1rem;

        > svg,
        > img {
            width: 100%;
            height: 100%;
        }
    }

    &:disabled {
        opacity: 0.7;
    }

    &.red {
        $color: #ff0000de;
        color: $color;
        border-color: rgba($color, 0.5);
        background-color: rgba($color, 0.1);
    }
    &.green {
        $color: #008937;
        color: $color;
        border-color: $color;
        background-color: rgba($color, 0.1);
    }
}
