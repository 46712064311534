.comp-tabs {
    position: relative;
    overflow: hidden;

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        background: $primary-purple;
        opacity: 0.1;
        z-index: -1;
    }

    &--link {
        padding: 1.125rem 3.5rem;
        display: inline-block;
        border-radius: 0.5rem 0.5rem 0 0;
        font-size: 1.125rem;
        line-height: 27px;
        font-weight: 500;
        letter-spacing: 0.04em;
        user-select: none;
        cursor: pointer;

        &.active {
            font-weight: 700;
            background: $primary-purple;
        }

        @include respond(sm) {
            padding: 0.844rem 1.5rem;
            font-size: 0.875rem;
            font-weight: 400;

            &.active {
                font-weight: 600;
            }
        }
    }

    &--effect {
        position: relative;
        &::after,
        &::before {
            content: '';
            position: absolute;
            opacity: 0.5;
            @extend .background-linear-gradient;
        }
        &::after {
            top: -540px;
            right: -400px;
            width: 754px;
            height: 754px;
        }
        &::before {
            bottom: -840px;
            left: -100px;
            width: 958px;
            height: 958px;
        }
    }
}
