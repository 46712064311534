.aphal-progress {
    img {
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-delay: 0ms;
        animation-fill-mode: forwards;
        transition: all 0.5s ease;

    }
    .process {
        --w: 90%;
        height: 12px;
        background-color: #152938;
        border-radius: 20px;
        width: 100%;
        position: relative;

        &__inner {
            content:'';
            border-radius: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--w);
            height: 100%;
            // transition: width 0.3s ease;    
            background-color: #008937;
        }
    }

    &.complete {
        transition: all 3s ease;
        opacity: 0;
        img {
            animation: unset;
        }
        .process {
            --w: 100%;
            
            &__inner {
                transition: width 0.3s ease-out;
            }
    
        }
    }

}



@keyframes rotate {
    0% {
        transform: rotate(deg0);

    }
    100% {
        transform: rotate(360deg);
    }
}
