$color-primary: #3464fd;
$color-secondary: #cf2e8c;
$color-link: #40a9ff;
$color-background: #191838;

$color-primary-gradient: linear-gradient(
    133.59deg,
    #9406fe 2.34%,
    #0b34f5 100%
);
$color-secondary-gradient: linear-gradient(
    134.23deg,
    #01d4c3 -23.36%,
    #048ffb 100%
);

$color-white: #ffffff;
$color-white-1: #f5f5f5;
$color-white-2: #d9d9d9;

$color-black: #000000;
$color-black-1: #0e0d17;

$color-dark: #302f4c;
$color-dark-02: #363551;
$color-dark-03: #201f3e;
$color-dark-04: #252442;
$color-error: #ff4d4f;
$color-error-1: #ff0000;
$color-error-2: #d0284c;

/*  */
$bg-01: #191838;
$bg-02: #151432;
$bg-content: #2b2a48;
/*  */
$primary-purple-01: #9406fe;
$primary-purple-02: #0b34f5;
$primary-purple-05: #8d07f3;
$primary-blue-01: #01d4c3;
$primary-blue-02: #048ffb;

$primary-purple: linear-gradient(
    133.59deg,
    $primary-purple-01 2.34%,
    $primary-purple-02 100%
);
$primary-blue: linear-gradient(
    134.23deg,
    $primary-blue-01 -23.36%,
    $primary-blue-02 100%
);
/*  */

$text-01: rgba(255, 255, 255, 0.87);
$text-02: rgba(255, 255, 255, 0.6);
