.accordion {
    overflow: hidden;

    .accordion_body {
        max-height: 0;
        transition: max-height 0.3s ease;
    }
    
    .accordion__title {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        &::after {
            $w: 14.9px;
            $h: 8.78px;
            content: '';
            width: $w;
            height: $h;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 2.5rem;
            background-image: url('/src/assets/svgs/arrow-down.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: transform 0.5s ease;
        }

        @include respond(sm) {
            font-size: 14px;
            &::after {
                right: 22px;
                top: calc(50% - 1px);
            }
        }
    }

    &.accordion__open {
        .accordion__title {
            &::after {
                transform: rotate(-180deg);
                transform-origin: center;
            }
        }

        .accordion_body {
            --max-height: 0;
            max-height: var(--max-height);
        }
        
    }
}
