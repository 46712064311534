.background-linear-gradient {
    background: linear-gradient(
        180deg,
        $primary-purple-02 0%,
        $primary-purple-01 100%
    );
    opacity: 0.5;
    border-radius: 50%;
    z-index: -1;
    filter: blur(130px);
}

.scroll-bar {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color-dark-02;
        border-radius: 5px;
    }
}

.section-content {
    max-width: 1280px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;

    @include respond(2xl) {
        max-width: 1280px;
    }

    @include respond(xl) {
        max-width: 1024px;
    }

    @include respond(lg) {
        max-width: 768px;
    }

    @include respond(md) {
        max-width: 640px;
    }

    @include respond(sm) {
        max-width: 100%;
    }
}
