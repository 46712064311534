#mobile-video {
    display: none;
}

@media screen and (max-width: 767.98px) {
    #mobile-video {
        display: block;
        pointer-events: none;
    }
    #main-video {
        display: none;
    }
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-bar-container {
    width: 60%;
    height: 20px;
    background-color: white;
    border-radius: 12px;
    position: absolute;
    overflow: hidden;
}

.loading-bar {
    height: 20px;
    background-color: $color-primary;
    display: block;
    font-size: 12px;
    text-align: center;
}
