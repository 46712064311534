.warning-notice  {
    &-left {
        position: relative;
        transition: all 0.5s ease-in-out;
        margin-left: 6px;
    }

    &-left::before {
        content: '';
        display: block;
        border: 6px solid transparent;
        border-right-color: #252442;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);

    }

    &-right {
        position: relative;
        transition: all 0.5s ease-in-out;
        margin-right: 6px;
    }

    &-right::before {
        content: '';
        display: block;
        border: 6px solid transparent;
        border-left-color: #252442;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);

    }

    &-top {
        position: relative;
        transition: all 0.5s ease-in-out;
        margin-top: 6px;
    }

    &-top::before {
        content: '';
        display: block;
        border: 6px solid transparent;
        border-bottom-color: #252442;
        position: absolute;
        left: 22px;
        bottom: 100%;
    }

    &-bottom {
        position: relative;
        transition: all 0.5s ease-in-out;
        margin-top: 6px;
    }

    &-bottom::before {
        content: '';
        display: block;
        border: 6px solid transparent;
        border-top-color: #252442;
        position: absolute;
        left: 22px;
        top: 100%;
    }
}

@include respond(phone) {
    .sm-warning-notice  {
        &-left {
            position: relative;
            transition: all 0.5s ease-in-out;
            margin-left: 6px;
        }
    
        &-left::before {
            content: '';
            display: block;
            border: 6px solid transparent;
            border-right-color: #252442;
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
    
        }
    
        &-right {
            position: relative;
            transition: all 0.5s ease-in-out;
            margin-right: 6px;
        }
    
        &-right::before {
            content: '';
            display: block;
            border: 6px solid transparent;
            border-left-color: #252442;
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
    
        }
    
        &-top {
            position: relative;
            transition: all 0.5s ease-in-out;
            margin-top: 6px;
        }
    
        &-top::before {
            content: '';
            display: block;
            border: 6px solid transparent;
            border-bottom-color: #252442;
            position: absolute;
            left: 22px;
            bottom: 100%;
            top: auto;
        transform: initial;
        }
    
        &-bottom {
            position: relative;
            transition: all 0.5s ease-in-out;
            margin-top: 6px;
        }
    
        &-bottom::before {
            content: '';
            display: block;
            border: 6px solid transparent;
            border-top-color: #252442;
            position: absolute;
            left: 22px;
            top: 100%;
        }
    }
}