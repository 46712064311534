.modal-wraper {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    z-index: 1200;
    display: none;

    .modal {
        position: absolute;
        top: 20%;
        max-width: 100%;
        padding: 2rem;
        border-radius: 1rem;
        left: 50%;
        transform: translateX(-50%);
        top: -100%;
        animation-duration: 500ms;
        animation-fill-mode: forwards;

        &__header {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            /* identical to box height */

            letter-spacing: 0.04em;

            color: rgba(255, 255, 255, 0.87);
            margin-bottom: 1rem;

            @include respond(phone) {
                font-size: 16px;
            }
        }

        @include respond(md) {
            width: calc(100% - 32px);
        }
    }

    &.open {
        display: block;

        .modal {
            top: 20%;
            animation-name: fly-in;

            &.out {
                animation-name: fly-out;
            }
        }
    }
}

@keyframes fly-in {
    0% {
        top: -100%;
    }
    100% {
        top: 20%;
    }
}

@keyframes fly-out {
    0% {
        top: 20%;
    }
    100% {
        top: -100%;
    }
}

#modal-coming-soon {
    > div {
        background-image: url('../assets/images/modal-background.png');
        background-size: cover;
    }
}
