.form-switch input {
    position: relative;
    
}
.form-switch input::after {
    content: '';
    $w: 10px;
    width: $w;
    height: $w;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $bg-01;
    border-radius: 50%;
    transition: left 0.5s ease;
}

.form-switch input:checked {
    background-color: $primary-purple-01;
    &::after {
        content: '';
        left: calc(calc(100% - 3px) - 10px);
    }
}