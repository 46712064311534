.alpha-puark--input {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(white, 0.2);
    background-color: rgba(white, 0.05);
    padding: 1rem 2rem;
    transition: border-color 0.1s ease;

    &--icon {
        &__left,
        &__right {
            width: 2rem;
            height: 2rem;

            > img,
            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    input {
        width: 100%;
    }

    > input {
        background-color: transparent;
        outline: none;
        font-size: 1.125rem;
        letter-spacing: 0.04rem;
        line-height: 1.75rem;
        color: rgba(white, 0.87);

        &::placeholder {
            color: rgba(white, 0.35);
        }

        &[type='number'] {
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}
