* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
}

body {
    box-sizing: border-box;
    background-color: $color-background;
}

@for $i from 1 through 4 {
    .text-#{$i}-line {
        @include truncate($i)
    }
}

@include respond(phone) {
   .sm\:text-free {
        display: initial !important;
        overflow: initial !important;
   }

   * {
        outline: none;
   }
}

